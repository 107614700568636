$app-background-color: #eaeefd;
$primary-color: #bd65cf;
$primary-color-light: #f1964b;
$secondary-color: #273143;
$secondary-color-light: #273143c9;
$success-color: #38a73e;
$warning-color: #b13d37;
$subtle-color: #bbb;
$dark-text-color: #273143;
$light-color: #777;
$light-text-color: #555;
$ultra-light-text-color: #959595;

$white-color: #fff;

$light-gray-background: #f2f2f2;
$light-medium-gray-background: #eff3f6;

$default-border-radius: 20px;
$app-header-height: 95px; // by changing this value, check sticky headers that rely on this height in some TypeScript files.

$card-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
$card-box-shadow-large: 0 2px 6px -1px rgba(0, 0, 0, 0.4), 0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.2);

$primary-image-filter-color: invert(30%) sepia(96%) saturate(1297%) hue-rotate(221deg) brightness(101%) contrast(106%);

$divider-default-color: rgba(0, 0, 0, 0.12);
$table-row-striped-color: rgba(0, 0, 0, 0.03);
$table-column-group-border-color: rgb(224, 224, 224);

$card-min-height: 114px;

$app-width: 1600px;
$side-navigation-width: 300px;

// market explorer segments
$segment-color-1: #4eacc4;
$segment-color-2: #cc8145;
$segment-color-3: #b055c7;
$segment-color-4: #b53c3c;
$segment-color-5: #55c770;
$segment-color-6: #b9bf1f;
$segment-color-top-game: #ffc000;

// goole signup colors
$google-signup-button-color: #4285f4;
$google-signup-button-hover-color: #3367d6;

$primary-font-family: Walsheim-Regular, sans-serif;

/**
breakpoints replicated from Mui theme https://mui.com/material-ui/customization/breakpoints/

xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px
*/
$breakpoint-xl: 1536px;
$breakpoint-lg: 1200px;
$breakpoint-md: 900px;
$breakpoint-sm: 600px;
$breakpoint-xs: 480px;

// other breakpoints
$breakpoint-fullscreen: $app-width;

$base-spacing: 8px;
@function spacing($multiplier) {
  @return $base-spacing * $multiplier;
}
