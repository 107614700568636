@import '../../variables';

.SideNavigation {
  position: fixed;
  margin-left: 20px;
  top: 20px;
  bottom: 20px;
  width: $side-navigation-width;
  box-sizing: border-box;

  &--mobile {
    top: 10px;
    margin-left: 10px;
    bottom: 10px;
  }

  &__divider {
    margin: 13px -1rem;
  }

  &__content {
    position: absolute;
    top: 75px;
    bottom: 0px;
    left: 0;
    padding: 20px 20px 0px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    &__mobile-extension {
      margin-top: -20px;
      margin-bottom: 5px;

      &__account {
        margin-right: -7px;
      }
    }
  }

  &__Logo {
    position: relative;
    text-align: center;
    margin: 0 auto;
    z-index: 2;

    img {
      width: 160px;
    }
  }

  &__Badges {
    position: absolute;
    bottom: -33px;
    width: 100%;

    &__Badge {
      z-index: 2;
      display: inline-block;

      .Chip {
        background-color: #ddd;
      }

      &--Beta {
        .Chip {
          background-color: $secondary-color;
          color: white;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  &__DataGlossary {
    &__active {
      .SideNavigation__DataGlossary__button {
        color: $primary-color;
        font-weight: bold;
      }
    }
  }

  &__BookmarksButtonsWrapper {
    position: relative;
    margin-bottom: 20px;
    display: flex;

    &__left {
      flex-grow: 1;
    }

    &__right {
      position: relative;
      margin-left: 5px;

      .MuiButtonBase-root {
        padding: 7px !important;
      }

      .MuiButton-root {
        min-width: auto !important;
        border-bottom-left-radius: 20px; 
        border-top-left-radius: 20px;
      }

      .MuiButton-startIcon {
        margin: 0px !important;
      }
    }
  }

  &__hideNavigationButton--rounded {
    border-radius: 20px !important;
  }

  &__FullscreenModeToggler {
    button {
      border: none;
      font-size: inherit;
    }

    &--visible {
      height: 24px;
      width: 24px;
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
    &--hidden {
      width: 24px;
      height: 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  &__Toolbar {
    background-color: #cecece;
    button {
      border-radius: 20px;
      color: rgba(0, 0, 0, 0.87);  
      background-color: #ddd;
    
      padding: 4px 6px;      
      min-width: 24px !important;
      width: 24px;
      height: 24px;

      transition: all 0.3s ease-in-out;
      background-color: #cecece;

      &:hover {
        background-color: #cecece;
      }
    }

    .MuiButtonGroup-firstButton {
      border-color: darken(#cecece, 3%);  
     }
  }
}

